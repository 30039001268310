import React from "react";

import { Link } from "react-router-dom";

const GitHubLink: React.FC = () => {
  return (
    <p>
      完整的程式碼請參考{" "}
      <Link
        to="https://github.com/samtash1034/Sam-Blog-Example"
        target="_blank"
      >
        GitHub
      </Link>{" "}
    </p>
  );
};

export default GitHubLink;

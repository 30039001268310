import React from "react";

import { useParams, Link } from "react-router-dom";
import { MdNavigateNext } from "react-icons/md";

import { useGetArticlesByCategoryQuery } from "services/api/categoryService";
import Header from "components/Header";
import Footer from "components/Footer";
import Pagination from "components/Pagination";
import Loading from "components/Loading";
import Item from "pages/Category/item";
import usePageClick from "../../hooks/uesPageClick";

import "./style.scss";

import useCheckServerError from "hooks/useCheckServerError";

const CategoryPage: React.FC = () => {
  const { currentPage, handlePageClick } = usePageClick();

  const { type } = useParams();
  const { data, isLoading, isError, error } = useGetArticlesByCategoryQuery({
    type: type!,
    page: currentPage,
    limit: 10,
  });
  useCheckServerError(isError, error);

  if (isLoading) {
    return <Loading />;
  }

  let breadcrumbType = type;
  if (type === "network") {
    breadcrumbType = "網路";
  }
  if (type === "reading") {
    breadcrumbType = "閱讀心得";
  }
  if (type == "database") {
    breadcrumbType = "資料庫";
  }

  return (
    <>
      <Header />
      <main>
        <div className="container archive-header">
          <h2>{breadcrumbType}</h2>
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <MdNavigateNext className="nav-icon" />
            <span /> {breadcrumbType}
          </div>
          <div className="horizontal-line bt-1" />
        </div>

        <div className="article-list-sectioon container">
          <div className="row">
            <div className="col-lg-8">
              {!isLoading &&
                data!.result.data.map((item) => (
                  <Item key={item.id} item={item} type={type!} />
                ))}
              <div className="col-12">
                <Pagination
                  totalCount={data!.result.totalCount}
                  onPageChange={handlePageClick}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default CategoryPage;

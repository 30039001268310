import React from "react";

import { Link } from "react-router-dom";
import { TfiRulerPencil } from "react-icons/tfi";
import { CiViewList } from "react-icons/ci";
import { MdSearch } from "react-icons/md";
import { FaMoon } from "react-icons/fa";
import { TbMinusVertical } from "react-icons/tb";
import { RiArrowDropDownLine } from "react-icons/ri";
import { TbTimeline } from "react-icons/tb";

import { useNotice } from "hooks/useNotice";

import logo from "assets/imgs/theme/logo.png";
import "./style.scss";

const Header: React.FC = () => {
  const showNotice = useNotice();
  const [isNavExpanded, setIsNavExpanded] = React.useState(false);
  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="row header-content">
            <div className="col-md-3">
              <Link to="/">
                <img className="logo" src={logo} alt="Sam's Blog Logo" />
              </Link>
            </div>
            <div className="navigation col-md-9">
              <ul className="list-inline nav-topbar">
                <li className="list-inline-item menu-item-has-children">
                  <Link to="/">
                    <div className="icon-container">
                      <TfiRulerPencil className="navigation-icon" />
                      文章分類
                      <RiArrowDropDownLine className="dropdown-icon" />
                    </div>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/category/java">Java</Link>
                    </li>
                    <li>
                      <Link to="/category/network">網路</Link>
                    </li>
                    <li>
                      <Link to="/category/security">Security</Link>
                    </li>
                    <li>
                      <Link to="/category/database">資料庫</Link>
                    </li>
                    <li>
                      <Link to="/category/other">Other</Link>
                    </li>
                  </ul>
                </li>
                <li className="list-inline-item reading-container">
                  <Link to="/category/reading">
                    <CiViewList className="navigation-icon" />
                    閱讀心得
                  </Link>
                </li>
              </ul>
              <TbMinusVertical
                className="vertical-icon"
                style={{ strokeWidth: "1px" }}
              />
              <button
                className="search-btn d-md-inline"
                onClick={() => showNotice()}
              >
                <span>
                  <MdSearch className="navigation-icon" />
                  Search
                </span>
              </button>
              <button className="timeline-button">
                <Link to="/timeline">
                  <TbTimeline className="navigation-icon timeline-icon" />
                </Link>
              </button>
              <Link className="about-btn" to="/about">
                關於我
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
